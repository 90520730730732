import React, {Component} from "react";

import cx from "classnames";
import Collapse from "@kunukn/react-collapse";


class CollapsibleWrapper extends Component {
    state = {
        isOpen2: false, resources: [{name: 'Skela', count: '3000 m²'}, {
            name: 'Građevinski cirkular', count: '2 kom'
        }, {name: 'Građevinske dizalice nosivosti 200-500kg', count: '3 kom'},

        //     {
        //     name: 'Teretni kombi LT35', count: '1 kom'
        // },

            {
                name: 'Mešalica 350l', count: '5 kom'
            },

            {name: 'Građevinska kontenjera', count: '2 kom'}, {
            name: 'Kran PORAIN HD 32A', count: '2 kom'
        }, {name: 'Građevinski podupirači', count: '4300 kom'}, {
            name: 'Vibrator', count: '4 kom'
        }, {name: 'Vibro letva', count: '1 kom'},
            {name: 'Vibro ploča', count: '1 kom'},
            {
            name: 'Viljuškar TOYOTA', count: '1 kom'
        }, {name: 'Kamion kipper', count: '1 kom'},
        //     {
        //     name: 'Putnički automobil', count: '1 kom'
        // },
            {
                name: 'Oplate za ploče', count: '1000 M2 '
            },
            {
            name: 'Otplate za stubove i zidove', count: 'veći broj'
        }, {name: 'Magacin sa testerskim i bravarskim pogonom', count: 'vidi sliku'}]
    };

    toggle = index => {
        let collapse = "isOpen" + index;

        this.setState(prevState => ({[collapse]: !prevState[collapse]}));
    };

    render() {
        return (<>
            <div
                className={cx("collapsable app__toggle", {
                    "collapsable app__toggle--active": this.state.isOpen2
                })}
                onClick={() => this.toggle(2)}
            >
                <p className='m-0 font-bold text-lightBlue text-[25px] '>
                    Spisak osnovnih sredstava preduzeća
                </p>
            </div>

            <Collapse
                isOpen={this.state.isOpen2}
                className={"app__collapse app__collapse--gradient " + (this.state.isOpen2 ? "app__collapse--active" : "")}
                transition="height 800ms cubic-bezier(0.4, 0, 0.2, 1)"
                aria-hidden={this.state.isOpen2 ? "false" : "true"}
                elementType="article"
                render={collapseState => (<React.Fragment>
                    <div className="app__content">
                        {this.state.resources.map(resource => {
                            return (<div
                                key={resource.name}
                                className='flex justify-between px-[20px] lg:px-[85px] py-[12px] my-[12px] mx-[auto] bg-white'>
                                <p className='text-left text-[13px] md:text-[14px]'>{resource.name}</p>
                                <p className='text-right text-[13px] md:text-[14px]'>{resource.count}</p>
                            </div>)
                        })}
                    </div>
                </React.Fragment>)}
            />

        </>)
    }
}

export default CollapsibleWrapper