import React from "react";
import BigGallery from "../components/BigGallery";
import CollapsibleWrapper from "../components/CollapsibleWrapper";

import magacin1 from '../assets/images/delatnost/warehouse/1.jpg'
import magacin2 from '../assets/images/delatnost/warehouse/2.jpg'
import magacin3 from '../assets/images/delatnost/warehouse/3.jpg'

import image1 from '../assets/images/delatnost/gallery/1.jpg';
import image2 from '../assets/images/delatnost/gallery/2.jpg';
import image3 from '../assets/images/delatnost/gallery/3.jpg';
import image4 from '../assets/images/delatnost/gallery/4.jpg';
import image5 from '../assets/images/delatnost/gallery/5.jpg';
import image6 from '../assets/images/delatnost/gallery/6.jpg';
import image7 from '../assets/images/delatnost/gallery/7.jpg';
import image8 from '../assets/images/delatnost/gallery/8.jpg';
import image9 from '../assets/images/delatnost/gallery/9.jpg';
import image10 from '../assets/images/delatnost/gallery/10.jpg';

const images = [
    {
        original: image1,
        thumbnail: image1,
    },
    {
        original: image2,
        thumbnail: image2,
    },
    {
        original: image3,
        thumbnail: image3,
    },
    {
        original: image4,
        thumbnail: image4,
    },
    {
        original: image5,
        thumbnail: image5,
    },
    {
        original: image6,
        thumbnail: image6,
    },
    {
        original: image7,
        thumbnail: image7,
    },
    {
        original: image8,
        thumbnail: image8,
    },
    {
        original: image9,
        thumbnail: image9,
    },
    {
        original: image10,
        thumbnail: image10,
    }
]

const Delatnost = () => {

    return (<>
        <section id='experience'>
            <div className='flex flex-col md:flex-row lg:h-[70vh]'>
                <div
                    className='flex  flex-col-reverse  items-center p-[30px] lg:p-[0]  md:w-[50%] justify-center'>
                    <div className='text-left max-w-[525px]'>
                        <h1 className='md:text-[38px] text-lightBlue font-normal'>Delatnost</h1>
                        <p>Tokom 30 godina poslovanja u tržišnim uslovima preduzeće KOMPAKT se
                            specijalizovalo za izvođenje grubih građevinskih radova. Danas
                            posedujemo svu potrebnu opremu i mehanizaciju za obavljanje poslova na
                            objektima visokogradnje.</p>
                        <p>
                            Ukoliko ste zainteresovani za izgradnju, KOMPAKT  je odgovoran i pouzdan
                            partner. Garant tome su brojni uspešno izgradjeni objekti kao i
                            zadovoljni naručioci posla.
                        </p>

                    </div>
                </div>
                <div className='hidden md:flex-1  md:block md:flex-grow activity-pic  h-[300px] md:h-[70vh]'>

                </div>

            </div>

        </section>
        <section id='activity-big-gallery' className='p-[20px] md:p-[100px]'>
            <BigGallery
                images={images}
            />
        </section>
        <section id='warehouse' className='text-center bg-backgroundGrey py-[50px] md:py-[130px]'>
            <h2>Magacin i Skladište</h2>
            <p className='mb-[50px]'>Gornja Vrežina, Industrijska zona</p>
            <div className='flex flex-col lg:flex-row justify-center items-center gap-[30px] max-w-[100wh]'>
                <img className='h-[237px] w-[400px] transition-[1s] hover:scale-[1.3]' src={magacin1}
                     alt="Kompakt Magacin 1"/>
                <img className='h-[237px] w-[400px] transition-[1s] hover:scale-[1.3]' src={magacin2}
                     alt="Kompakt Magacin 2"/>
                <img className='h-[237px] w-[400px] transition-[1s] hover:scale-[1.3]' src={magacin3}
                     alt="Kompakt Magacin 3"/>
            </div>
        </section>

        <section id='list' className='text-center bg-backgroundGrey pb-[130px] px-[20px] lg:px-[100px]'>

            <CollapsibleWrapper/>


        </section>
    </>)
}

export default Delatnost