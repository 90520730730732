import React from 'react'
import whiteLogo from '../assets/images/logo-white.png'

const Footer = () => {


    return (<footer
        className='flex flex-col md:flex-row  bg-lightBlue flex justify-between px-[30px] py-[26px] md:px-[114px]'>
        <div className='flex flex-col items-center'>
            <img className='w-[140px]' src={whiteLogo} alt="Kompakt logo beli"/>
            <p className='text-white text-center m-0'>građevinsko-proizvodno <br/> preduzeće</p>
        </div>
        <div className='flex flex-col items-center lg:items-start justify-center'>
            <p className='text-white text-center mb-[20px] font-montserrat'>Kontakt:</p>
            <p className='text-white text-center my-[5px] font-montserrat'>
                tel. +381 63 669-411
            </p>
            <p className='text-white text-center my-[5px] font-montserrat'>
                fax. +381 18 533-197
            </p>
            <p className='text-white text-center my-[5px] font-montserrat'>
                e-mail: <a className='text-white hover:!underline visited:text-white' rel="noreferrer" target='_blank'
                           href="mailto:info@kompakt.rs">info@kompakt.rs</a>
            </p>
        </div>
        <div className='flex flex-col items-center  justify-end'>
            <p className=' text-center lg:text-right text-white font-montserrat'>© 1992 - 2022 Kompakt d.o.o. <br/>
                Sva prava vlasnika materijala zadržana</p>
        </div>

    </footer>)
}

export default Footer