import React, {useState} from "react";
import kompaktLogo from '../assets/images/logo-new.png'
import kompaktArtLogo from '../assets/images/kompakt-art-logo.png'
import {Link} from "react-router-dom";

const pages = [{name: 'Naslovna', url: '/'},
    {name: 'O Nama', url: 'o-nama'},
    {name: 'Delatnost', url: '/delatnost'}, {
        name: 'Referenc Lista',
        url: 'referenc-lista'
    }, {name: 'Foto Galerija', url: 'foto-galerija'}, ,

]


const Navbar = () => {

    const [currentPage, setCurrentPage] = useState('Naslovna')
    const [menuOpen, setMenuOpen] = useState(false)


    return (

        <nav>
            <div className='flex justify-center xl:hidden pt-[20px]'>
                <Link to='/'>
                    <img src={kompaktLogo} alt="Kompakt Logo"/>
                </Link>
            </div>
            <div id='linkHolder' className='flex 2xl:px-[100px]  px-[20px]  py-[20px] items-center'>
                <div className='lg:hidden cursor-pointer'>
                    <nav role="navigation">
                        <div onClick={() => setMenuOpen(!menuOpen)} id="menuToggle">

                            <input onChange={() => {
                                return ''
                            }} checked={menuOpen} type="checkbox"/>

                            <span></span>
                            <span></span>
                            <span></span>


                            <ul id="menu">


                                {pages.map(page => {
                                    return (<li key={page.name}>
                                        <Link onClick={() => {
                                            setMenuOpen(false)
                                            setCurrentPage(page.name)
                                        }} to={page.url}>
                                            {page.name}
                                        </Link>
                                    </li>)
                                })}
                            </ul>
                        </div>
                    </nav>
                </div>
                <div className='flex flex-1 flex-wrap hidden flex-col lg:block'>
                    {pages.map(page => {
                        return (
                            <Link key={page.name} to={page.url}
                                  className={`font-montserrat hover:text-lightBlue transition-[.3s] text-sm mr-[16px] ${page.name === currentPage ? '!text-lightBlue' : ''}`}
                                  onClick={() => setCurrentPage(page.name)}>{page.name}</Link>)
                    })}

                </div>
                <div className='mx-[30px] flex justify-center hidden xl:block'>
                    <Link onClick={() => {
                        setCurrentPage('Naslovna')
                    }} to='/'>
                        <img src={kompaktLogo} alt="Kompakt logo"/>
                    </Link>
                </div>
                <div className=' flex gap-y-[10px] sm:flex-row sm:flex-1 justify-end'>
                    <a target='_blank' href="http://www.kompakt.rs/art/">
                        <img className='mr-[34px]' src={kompaktArtLogo}
                             alt="Kompakt Art Logo"/>
                    </a>
                    <Link onClick={() => setCurrentPage('Kontakt')} to='/kontakt'>
                        <div
                            className='bg-lightBlue px-[35px] py-[10px] mr-[35px] transition-[.3s] hover:opacity-80 text-white cursor-pointer'>
                            Kontakt
                        </div>
                    </Link>
                    {/*<select defaultValue='🇷🇸 SRB' name="language" id="language">*/}
                    {/*    <option value="srb">🇷🇸 SRB</option>*/}
                    {/*    <option value="eng">🇬🇧 ENG</option>*/}
                    {/*</select>*/}

                </div>
            </div>
        </nav>

    )
}

export default Navbar