import React, {useState} from "react";
import data from "../assets/images.json";
import Modal from "./Modal";


const GalleryContainer = () => {

    const [clickedImg, setClickedImg] = useState(null);
    const [clickedText, setClickedText] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);

    const handleClick = (item, index) => {
        setCurrentIndex(index);
        setClickedImg(item.link);
        setClickedText(item.text)
    };

    const handelRotationRight = () => {
        const totalLength = data.data.length;
        if (currentIndex + 1 >= totalLength) {
            setCurrentIndex(0);
            const newUrl = data.data[0].link;
            setClickedImg(newUrl);
            return;
        }
        const newIndex = currentIndex + 1;
        const newUrl = data.data.filter((item) => {
            return data.data.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].link;
        const newItemText = newUrl[0].text;
        setClickedImg(newItem);
        setClickedText(newItemText);
        setCurrentIndex(newIndex);
    };

    const handelRotationLeft = () => {
        const totalLength = data.data.length;
        if (currentIndex === 0) {
            setCurrentIndex(totalLength - 1);
            const newUrl = data.data[totalLength - 1].link;
            const newText = data.data[totalLength - 1].text;
            setClickedImg(newUrl);
            setClickedText(newText);
            return;
        }
        const newIndex = currentIndex - 1;
        const newUrl = data.data.filter((item) => {
            return data.data.indexOf(item) === newIndex;
        });
        const newItem = newUrl[0].link;
        const newItemText = newUrl[0].text;
        setClickedImg(newItem);
        setClickedText(newItemText);
        setCurrentIndex(newIndex);
    };

    return (<div className="gallery-wrapper">
        {data.data.map((item, index) => (<div key={index} className="wrapper-images">
            <img
                src={`${item.link}`}
                alt={item.text}
                onClick={() => handleClick(item, index)}
            />
            <p className='text-center text-lightBlue'>{item.text}</p>
        </div>))}

        {clickedImg && (<Modal
            clickedImg={clickedImg}
            handelRotationRight={handelRotationRight}
            clickedText={clickedText}
            setClickedImg={setClickedImg}
            handelRotationLeft={handelRotationLeft}
        />)}

    </div>)
}

export default GalleryContainer