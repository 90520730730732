import './assets/style/style.scss'
import {Routes, Route} from "react-router-dom";
import Navbar from "./components/Navbar";
import Naslovna from "./pages/Naslovna";
import Delatnost from "./pages/Delatnost";
import ReferencLista from "./pages/ReferencLista";
import FotoGalerija from "./pages/FotoGalerija";
import ONama from "./pages/ONama";
import Kontakt from "./pages/Kontakt";
import Footer from "./components/Footer";


function App() {

    return (

        <div className="App">
            <Navbar/>
            <main>
                <Routes>
                    <Route path="/" element={<Naslovna/>}/>
                    <Route path="/delatnost" element={<Delatnost/>}/>
                    <Route path="/referenc-lista" element={<ReferencLista/>}/>
                    <Route path="/foto-galerija" element={<FotoGalerija/>}/>
                    <Route path="/o-nama" element={<ONama/>}/>
                    <Route path="/kontakt" element={<Kontakt/>}/>
                </Routes>
            </main>
            <Footer/>
        </div>

    );
}

export default App;
