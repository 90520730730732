import React, {useState} from "react";
import locationMarker from '../assets/images/kontakt/location-marker.png'
import {send} from 'emailjs-com';
import kompaktLogo from '../assets/images/logo1.png'

import emailIco from '../assets/images/kontakt/email-ico.png'
import pibIco from '../assets/images/kontakt/pib-ico.png'

const Kontakt = () => {
    const [toSend, setToSend] = useState({
        ime: '', prezime: '', email: '', broj_telefona: '', povod_kontakta: '', poruka: '',
    });

    const [displaySuccess, setDisplaySuccess] = useState(false)
    const [displayError, setDisplayError] = useState(false)

    const onSubmit = (e) => {
        e.preventDefault();
        send('service_7vddbyn', 'template_42497c5', toSend, 'eyk7UsIGaDFCE_-C4')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setToSend({
                    ime: '', prezime: '', email: '', broj_telefona: '', povod_kontakta: '', poruka: ''
                })
                setDisplaySuccess(true)
                setDisplayError(false)
            })
            .catch((err) => {
                console.log('FAILED...', err);
                setDisplaySuccess(false)
                setDisplayError(true)
            });
    };

    const handleChange = (e) => {
        setToSend({...toSend, [e.target.name]: e.target.value});
    };


    return (<>
        <section className='px-[30px] lg:px-[100px] pb-[150px]'>
            <hr className='!mt-0 !mb-[70px]'/>
            <h1 className='text-center font-normal text-lightBlue mb-[60px] text-[40px] mb-[83px]'>Kontakt
                Informacije</h1>
            <div className="flex flex-col md:flex-row gap-[20px]">
                <div className='text-center flex-1 flex flex-col items-center'>
                    <p>Kancelarija</p>
                    <img src={locationMarker} className='mb-[10px]' alt="location"/>
                    <p> Milentijeva <br/> 17/6</p>
                    <iframe
                        className='border-[1px] border-lightBlue border-solid max-w-[600px]'
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2902.497175368857!2d21.899034215868124!3d43.324784379133966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4755b0c9ead9c2f7%3A0x379b15c9ab6e4f62!2sMilentijeva%2017%2C%20Ni%C5%A1!5e0!3m2!1sen!2srs!4v1650889518764!5m2!1sen!2srs"
                        width='90%' height="330" style={{border: 0}} allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                    <p>Niš, 18000 <br/> Srbija</p>
                    <p className='text-lightBlue'>
                        tel. +381 63 66 94 11 <br/>
                        fax. +381 18 533 197</p>
                </div>
                <div className='text-center flex-1 flex flex-col items-center'>
                    <p>Magacin i skladište</p>
                    <img src={locationMarker} className='mb-[10px]' alt="location"/>
                    <p> G. Vrežina - <br/> Industrijska zona</p>

                    <iframe
                        className='border-[1px] border-lightBlue border-solid max-w-[600px]'
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1676.0840972082356!2d21.978315658234173!3d43.32595994598938!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6376f8c2bd32bdbe!2zNDPCsDE5JzMzLjUiTiAyMcKwNTgnNDUuOSJF!5e1!3m2!1sen!2srs!4v1650890013933!5m2!1sen!2srs"
                        width='90%' height="330" style={{border: 0}} allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>

                    <p>Niš, 18000 <br/> Srbija</p>
                    <p className='text-lightBlue'>
                        tel. +381 63 11 66 963</p>
                </div>
            </div>
        </section>
        <section
            className='flex flex-wrap flex-col md:flex-row justify-center gap-x-[100px] px-[30px] md:px-[180px] pb-[200px]'>
            <div className='flex flex-col'>

                <p className='text-left'><img src={emailIco} alt="email" className='mr-[7px]'/>email: <a
                    className='text-lightBlue visited:text-lightBlue !hover:underline'
                    href="mailto:info@kompakt.rs">info@kompakt.rs</a></p>
                <p className='text-left'><img src={pibIco} alt="pib" className='mr-[7px]'/>PIB 100615372
                </p>
                <p className='text-left'><img src={pibIco} alt="pib" className='mr-[7px]'/>

                    M.BR. 07963351</p>
            </div>
            <div id='contact-form'>
                <img className='mx-[auto]' src={kompaktLogo} alt="Kompakt logo"/>
                <p className='text-lightBlue text-center'>Kontaktirajte nas!</p>
                <form onSubmit={onSubmit}>
                    <div className='flex justify-between gap-[20px]'>
                        <div className='mb-[25px] flex-1'>
                            <label htmlFor="ime">Ime</label>
                            <input
                                className='block'
                                type='text'
                                name='ime'
                                placeholder='Ime'
                                value={toSend.ime}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='mb-[25px] flex-1'>
                            <label htmlFor="prezime">Prezime</label>
                            <input
                                className='block'
                                type='text'
                                name='prezime'
                                placeholder='Prezime'
                                value={toSend.prezime}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='mb-[25px]'>
                        <label htmlFor="email">E-mejl</label>
                        <input
                            className='block'
                            type='email'
                            name='email'
                            placeholder='Email'
                            value={toSend.message}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='mb-[25px]'>
                        <label htmlFor="broj_telefona">Broj Telefona</label>
                        <input
                            className='block'
                            type='tel'
                            name='broj_telefona'
                            placeholder='Broj Telefona'
                            value={toSend.broj_telefona}
                            onChange={handleChange}
                        />
                    </div>

                    <div className='mb-[25px]'>
                        <label htmlFor="poruka">Poruka</label>
                        <textarea className='block' name="poruka" id="poruka" cols="30" value={toSend.poruka}
                                  onChange={handleChange}
                                  rows="10"></textarea>

                    </div>
                    <button className='w-[100%] bg-lightBlue text-white h-[55px] border-none cursor-pointer'
                            type='submit'>Pošalji
                    </button>
                </form>
                {displaySuccess ? <p className='text-green-500'>Poruka uspešno poslata!</p> : ''}

                {displayError ? <p className='text-red-600'>Greška pri slanju poruke...</p> : ''}


            </div>

        </section>
    </>)
}

export default Kontakt