import React from "react";
import ImageGallery from 'react-image-gallery';




;
const BigGallery = (props) => {

    return (

        <ImageGallery items={props.images} />
    )

}

export default BigGallery