import React from "react";
import galleryData from '../assets/galleryData.json'
import GalleryContainer from "../components/GalleryContainer";

const FotoGalerija = () => {


    return (<div className='px-[30px] lg:px-[100px] pb-[100px]'>
        <hr className='!mt-0 !mb-[70px]'/>
        <h1 className='text-center font-normal text-lightBlue mb-[60px]'>Foto Galerija</h1>

        <GalleryContainer

        />

    </div>)

}

export default FotoGalerija