import React from "react";
import video1 from '../assets/video/MedjuOblacima.mp4'
import video2 from '../assets/video/PonovoMedjuOblacima.mp4'

const ONama = () => {


    return (<>
        <section id='about-us-hero' className='h-[15vh] flex items-center md:h-[360px]'>
            <h1 className='text-white text-[28px] md:text-[64px] ml-[30px] md:ml-[100px] font-normal '>O nama</h1>
        </section>
        <section id='info-and-videos' className='px-[30px] pt-[89px] pb-[174px]'>
            <p className='text-center max-w-[830px] text-[20px] mx-[auto]'>
                Građevinsko-proizodno preduzeće KOMPAKT d.o.o. je osnovano 08.05.1992 godine u Nišu. Preduzeće je
                nastalo iz samostalne zanatske radnje osnovane 1975. i to kao nastavak dogogodišnje porodične tradicije
                bavljenja građevinskom delatnošću. Kompakt je danas nakon mnogih realizovanih projekata kvalifikovan,
                dobro opremljen i iskusan izvođač.
            </p>
            <div className='flex flex-col md:flex-row gap-[36px] justify-center items-center mt-[120px]'>
                <video className='w-[420px]' controls>
                    <source src={video1} type='video/mp4'/>
                </video>
                <video className='w-[420px]' controls>
                    <source src={video2} type='video/mp4'/>
                </video>


            </div>
        </section>
        <section></section>
    </>)
}

export default ONama