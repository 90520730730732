import React from "react";
import reference from '../assets/reference.json'

const ReferencLista = () => {


    return (<div className=' lg:px-[100px] pb-[100px]'>
        <hr className='!mt-0 !mb-[70px]'/>
        <h1 className='text-center font-normal text-lightBlue mb-[60px]'>Referenc Lista</h1>

        <section id='references'>


            <div className="flex justify-between mb-[6px]">
                <div
                    className='flex items-center justify-center max-w-[29%] sm:max-w-[24%] w-[100%] text-center bg-lightGrey min-h-[70px] text-[14px] sm:text-[24px] text-white'>
                    Lokacija
                </div>
                <div
                    className='flex items-center justify-center max-w-[29%] sm:max-w-[24%] w-[100%] text-center bg-lightGrey  min-h-[70px] text-[14px] sm:text-[24px] text-white'>
                    Vrsta Radova
                </div>
                <div
                    className='flex items-center justify-center max-w-[29%]  sm:max-w-[24%] w-[100%] text-center bg-lightGrey min-h-[70px] text-[14px] sm:text-[24px] text-white'>
                    Površina objekta
                </div>
                <div
                    className='flex items-center justify-center max-w-[10%]  sm:max-w-[24%] w-[100%] text-center bg-lightGrey min-h-[70px] text-[14px] sm:text-[24px] text-white'>
                    God
                </div>
            </div>

            {reference.map((referenca, index) => {
                return (<div key={index + referenca.lokacija}
                             className="flex justify-between reference-container mb-[11px]">
                    <div
                        className='flex items-center justify-center max-w-[29%] sm:max-w-[24%] w-[100%] text-center bg-backgroundGrey min-h-[60px]'>
                        {referenca.links.length === 0 ? referenca.lokacija :
                            <a  className='!underline !text-[#444446]' target='_blank' href={referenca.links[0]}>{referenca.lokacija}</a>
                        }
                    </div>
                    <div
                        className='flex items-center justify-center max-w-[29%] sm:max-w-[24%] w-[100%] text-center bg-backgroundGrey  min-h-[60px]'>
                        {referenca.vrstaRadova}
                    </div>
                    <div
                        className='flex items-center justify-center max-w-[29%] sm:max-w-[24%] w-[100%] text-center bg-backgroundGrey min-h-[60px]'>
                        {referenca.povrsinaObjekta}
                    </div>
                    <div
                        className='flex items-center justify-center  max-w-[10%] sm:max-w-[24%] w-[100%] text-center bg-backgroundGrey min-h-[60px]'>
                        {referenca.godina}
                    </div>
                </div>)
            })}
        </section>
    </div>)
}

export default ReferencLista