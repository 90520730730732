import React from "react";
import {Link} from "react-router-dom";

import BigGallery from "../components/BigGallery";

import image1 from '../assets/images/naslovna/carousel/1.jpg'
import image2 from '../assets/images/naslovna/carousel/2.jpg'
import image3 from '../assets/images/naslovna/carousel/3.jpg'
import image4 from '../assets/images/naslovna/carousel/4.jpg'


const images = [{
    original: image1, thumbnail: image1,
}, {
    original: image2, thumbnail: image2,
}, {
    original: image3, thumbnail: image3,
}, {
    original: image4, thumbnail: image4,
}]

const Naslovna = () => {


    return (<>
        <div className='px-[10%] pb-[10%]'>
            <section id='hero' className=' flex items-center'>
                <div className='bg-white py-[45px] pl-[70px] pr-[50px] md:pr-[100px] md:pl-[150px] rhomboid '>
                    <div className='inverse-rhomboid'>
                        <h1 className='hidden'>Kompakt d.o.o</h1>
                        <h2 className='text-[25px] lg:text-[50px]'>Vaš pouzdan partner <br/> u izgradnji</h2>
                        <p className='text-xs md:text-lg'>Predstavljamo Vam internet prezentaciju
                            građevinsko-proizvodnog<br/>
                            preduzeća KOMPAKT d.o.o iz Niša.
                        </p>
                    </div>
                </div>
            </section>
        </div>
        <section id='values' className='pb-[100px] text-g text-cent§er !px-[20px] lg:px-[100px]'>

            <h2 className='mt-0 md:text-[38px] font-normal text-center mb-[50px]'>Naše vrednosti</h2>

            <div
                className="flex flex-col justify-center sm:flex-row gap-x-[20px] flex-wrap">

                <div className='w-[100%] md:max-w-[23%] text-center  transition-[.3s] hover:bg-lightBlue hover:text-white'>
                    <h3 className='font-bold'>Profesionalizam</h3>
                    <p>Pored znanja i iskustva za posao kojim se bavimo, imamo moralne i etičke norme koje zahtevaju
                        savesnost
                        i
                        perfekciju u radu.</p>
                </div>
                <div className='w-[100%] md:max-w-[23%] text-center  transition-[.3s] hover:bg-lightBlue hover:text-white'>
                    <h3 className='font-bold'>Kredibilitet</h3>
                    <p>Kredibilitet u poslovnim odnosima se ne gradi preko noći, za njega je potrebno vreme i čitav niz
                        malih i velikih dela. Svojim radom i ponašanjem stekli smo ugled kao firma sa kojom je
                        zadovoljstvo
                        deliti poslovne ciljeve i aktivnosti.</p>
                </div>
                <div className='w-[100%] md:max-w-[23%] text-center  transition-[.3s] hover:bg-lightBlue hover:text-white'>
                    <h3 className='font-bold'>Poverenje</h3>
                    <p>Uspeli smo da izgradimo poslovne odnose zasnovane na poverenju. Naši klijenti ili saradnici
                        znajui
                        da nam je cilj zajednički napredak i uspeh u poslovanju.
                        Poslovni partneri koji veruju jedni drugima će provesti daleko manje vremena u pokušavanju
                        zaštite
                        svojih interesa i posvetiti se sopstvenom, ali i zajedničkom napretku.</p>
                </div>
                <div className='w-[100%] md:max-w-[23%] text-center  transition-[.3s] hover:bg-lightBlue hover:text-white'>
                    <h3 className='font-bold'>Poštovanje</h3>
                    <p>Kada se ljudi međusobno prihvataju u svojoj različitosti, postiže se najbolji put prema
                        zajedništvu
                        obeleženom poštovanjem.
                        Poštovanje stvara dobru atmosferu na poslu i pozitivne odnose među zaposlenima.</p>
                </div>
            </div>

        </section>
        {/*<section id='experience' className='m-b-[50px]'>*/}
        {/*    <div className='flex flex-col md:flex-row lg:h-[70vh]'>*/}
        {/*        <div*/}
        {/*            className='flex  flex-col-reverse  items-center p-[30px] lg:p-[0]  md:w-[50%] justify-center bg-backgroundGrey'>*/}
        {/*            <div className='text-left max-w-[525px]'>*/}
        {/*                <h2 className='md:text-[38px] font-normal'>Dobro opremljen i iskusan izvođač</h2>*/}
        {/*                <p className='text-sx md:text-[16px]'>Tokom godina poslovanja u tržišnim uslovima, preduzeće*/}
        {/*                    KOMPAKT se specijalizovalo za*/}
        {/*                    izvodjenje*/}
        {/*                    grubih građevinskih radova. Danas posedujemo svu potrebnu opremu i mehanizaciju za*/}
        {/*                    obavljanje*/}
        {/*                    poslova na objektima visokogradnje. Spremni smo da ispunimo sve veće zahteve koje pred nas*/}
        {/*                    postavljaju naručioci posla.</p>*/}
        {/*                <div className='mt-[100px] flex justify-end'>*/}
        {/*                    <Link to='/referenc-lista'>*/}
        {/*                        <div*/}
        {/*                            className='bg-lightBlue px-[35px] py-[10px] mr-[35px] transition-[.3s] hover:opacity-80 text-white cursor-pointer'>*/}
        {/*                            Referenc Lista*/}
        {/*                        </div>*/}
        {/*                    </Link>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*        <div className='md:flex-1 md:flex-grow experience-pic  h-[300px] md:h-[70vh]'>*/}

        {/*        </div>*/}

        {/*    </div>*/}

        {/*</section>*/}
        {/*<section id='carousel-gallery' className='text-center py-[50px] md:py-[135px] px-[40px] lg:px-[100px] '>*/}
        {/*    <h2 className='md:text-[38px] font-normal '>Sa ponosom Vam predstavljamo naših ruku*/}
        {/*        dela.</h2>*/}
        {/*    <hr className='max-w-[930px] w-[80%] m-[auto] bg-lightGrey'/>*/}
        {/*    <p className=' w-[100%] max-w-[700px] mx-[auto] mt-[20px] mb-[65px]'>*/}
        {/*        Ukoliko ste zainteresovani za izgradnju, KOMPAKT*/}
        {/*        vam pruža kvalitetno izvršenje svih poslova. Garant tome*/}
        {/*        su brojni uspešno izradjeni objekti, kao i zadovoljni komiteti.*/}
        {/*    </p>*/}

        {/*    <BigGallery*/}
        {/*        images={images}*/}
        {/*    />*/}

        {/*    <div className='flex justify-end mt-[30px] md:mt-[75px] max-w-[935px] mx-auto'>*/}
        {/*        <Link to='/foto-galerija'>*/}
        {/*            <div*/}
        {/*                className='border-lightBlue border-solid border-[1px] px-[35px] py-[10px] mr-[35px] transition-[.3s] hover:opacity-80 text-lightBlue cursor-pointer'>*/}
        {/*                Foto Galerija*/}
        {/*            </div>*/}
        {/*        </Link>*/}
        {/*    </div>*/}
        {/*</section>*/}


    </>)
}

export default Naslovna